$black: #000;
$white: #fff;
$gray: #ccc;
$light-gray: #e8e8e8;;
$blue: #3681D8;
$light-blue: #00D2FF;
$dark-blue: #080655;
$primary-color: #0c6e46;
$secondary-color: #333333;

$header-top-color: #eef2f6;
$font-color: #383c3e;
$banner-gray-color: #d2d2d2;
$bg-color: #f5f8fa;
$footer-bg-color: #1c1c1c;

$poppins-font: "Poppins", sans-serif;
$poppins-regular-font: "Poppins-Regular", sans-serif;
$sub-info-bg-color: #0c6e46;

@mixin border-radius-full() {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;    
}

@mixin border-radius-top() {
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}