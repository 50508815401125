@mixin extraSmallDevice {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin smallDevice {
    @media (max-width: 767.98px) {
        @content;
    }
}
@mixin mediumDevice {
    @media (max-width: 991.98px) {
        @content;
    }
}

@mixin largeDevice {
    @media (max-width: 1199.98px) {
        @content;
    }
}
@mixin extraLargeDevice {
    @media (min-width: 1200px) {
        @content;
    }
}
