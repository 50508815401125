@import "../scss/variables.scss";
@import "../scss/media.scss";

.login {
    .row {
        @include largeDevice {
            height: 100vh;
        }
        @include extraLargeDevice {
            height: 100vh;
        }
        @include smallDevice {
            height: auto;
        }
    }

    .bg-gray {
        background-color: $light-gray;
        position: relative;
    }

    .login-image {
        position: absolute;
        bottom: 0;
        left: 15px;

        @include smallDevice {
            display: none;
        }
        @include mediumDevice {
            display: none;
        }
    }

    .login-form-container {
        display: flex;
        align-items: center;
        justify-content: center;

        @include largeDevice {
            height: 100vh;
        }
        @include extraLargeDevice {
            height: 100vh;
        }
        @include smallDevice {
            height: auto;
            margin-top: 40px;
        }

        .login-form {
            max-width: 400px;

            .btn-primary {
                background-color: $primary-color;
            }
        }
    }
}