@import "../scss/variables.scss";
@import "../scss/media.scss";

html, body {
    margin: 0px;
    padding: 0px;
    min-height: 100%;
    background: $bg-color;
    font-family: $poppins-font;
    color: $font-color;
}
body.disableScroll {
    overflow: hidden;
}
* {
    box-sizing: border-box;
}
ul, li {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
a {
    text-decoration: none;
    color: inherit;
}
main {
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: block;
}

.header {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px;

    .user-agreement-modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

        .modal-content {
            background-color: #fefefe;
              margin: 10% auto; /* 15% from the top and centered */
              padding: 20px;
              border: 1px solid #888;
              width: 80%; /* Could be more or less, depending on screen size */
        }
            
        .close {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
        }
            
        .close:hover,
        .close:focus {
            color: black;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .headerTop {
        padding: 10px 0px;

        ul {
            display: inline-block;

            li {
                display: inline-block;
                margin-right: 10px;

                a {
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                    
                    @include largeDevice {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
        .btnProfile {
            border-radius: 10px;
            width: 155px;
            background: $font-color;
            color: #fff;
            display: inline-block;
            text-align: center;
            text-transform: uppercase;
            padding: 10px 15px;
            text-decoration: none;
        }
        .menuList {
            display: none;

            @include smallDevice {
                display: block;
                margin-left: 10px;
            }
        }
        .navMenu {
            @include mediumDevice {
                display: none !important;

                &.active {
                    display: block !important;
                    position: fixed;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    background: #fff;
                    width: 100% !important;
                    height: 100%;
                    z-index: 20;
                    scroll-behavior: contain;
                    overflow: auto;

                    ul {
                        display: block;
    
                        li {
                            display: block;
                            border-bottom: 1px solid $banner-gray-color;
                            margin: 0px;

                            a {
                                width: 100%;
                                padding: 10px;
                                display: block;
                            }
                        }
                        + .btnProfile {
                            padding: 10px;
                            display: block;
                            margin: 0px;
                            color: $font-color;
                            border-bottom: 1px solid $banner-gray-color;
                            background: none;
                            border-radius: 0px;
                            display: block;
                            width: 100%;
                            text-align: left;
                        }
                    }
                }
            }
        }
        .menuIcon {
            display: none;
            color: white;
            font-size: 40px;

            &:hover {
                cursor: pointer;
            }

            @include mediumDevice {
                display: block;

                &.active {
                    position: fixed;
                    z-index: 30;
                    top: -10px;
                    right: 10px;
                    background: #fff;
                }
            }
        }
    }
}

.productDetailWrapper {
    .accordion {
        .accordion-button {
            background-color: #efefef;
            color: #383c3e;
            font-weight: bold;

            &::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            }
            
            &:not(.collapsed) {
                background-color: #7accc8;
                color: #383c3e;
            }

            &:focus {
                box-shadow: none;
            }
        }
        .show {
            .accordion-body {
                background-color: #7accc8;
                color: #383c3e;
            }
        }
    }
}
.profile {
    background-color: $white;
    padding: 20px;
    @include border-radius-full();

    .profile-buttons {
        margin-top: 20px;

        .btn-password {
            background-color: $primary-color;
            color: $white;
            min-width: 150px;
        }

        .btn-logout {
            background-color: $black;
            color: $white;
            margin-top: 15px;
            min-width: 150px;
        }
    }
}
.education-status, .exam-status {
    background-color: $white;
    padding: 20px;
    @include border-radius-full();
}
.exam-status {
    background-color: #a3d39c;
}

.branch-status {
    background-color: $white;
    padding: 20px;
    @include border-radius-full();

    .title {
        background-color: $secondary-color;
        color: $white;
        padding: 10px;
    }
    .table {
        font-size: 12px;
    }
}
.breadCrumb {
    width: 100%;
    border-top: 1px solid $font-color;
    display: block;
    position: relative;
    padding: 0px;
    margin: 0px;

    .bgImage {
        background-position: right bottom;
        background-repeat: no-repeat;
        background-image: url("../images/breadCrumbBg.jpg");
        height: 100%;
        width: 100%;
        background-size: cover;

        &.bgImageFaq {
            background-image: url("../images/faq-banner.jpg");
        }

        &.bgImageExam {
            background-image: url("../images/exam-banner.jpg");
        }
    }
    .leftSide {
        display: flex;
        padding-left: 4.0em;
        justify-content: flex-start;
        align-items: center;
        font-size: 30px;
        font-weight: 600;
        height: 150px;
        color: $font-color;
        z-index: 11;

        @include smallDevice {
            height: 100px;
            padding-left: .75em;
        }

        &::before {
            background: $banner-gray-color;
            height: 100%;
            position: absolute;
            top: 0px;
            right: 54%;
            content: "";
            width: 100%;
            height: 100%;
            -webkit-transform: skew(45deg);
            -moz-transform: skew(45deg);
            -o-transform: skew(45deg);
            height: 100%;

            @include extraLargeDevice {
                right: 53%;
            }

            @include mediumDevice {
                right: 50%;
            }

            @include smallDevice {
                right: 0px;
                -webkit-transform: skew(0deg);
                -moz-transform: skew(0deg);
                -o-transform: skew(0deg);
            }
        }
        .title {
            font-size: 33px;
            font-weight: 700;
            color: $font-color;
            z-index: 12;

            @include mediumDevice {
                font-size: 19px;
            }
        }
    }
}

.mainWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 60vh;
    justify-content: center;
    padding-top: 5vw;
    padding-bottom: 50px;

    .quiz-container {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px 10px rgba(100, 100, 100, 0.1);
        width: 800px;
        overflow: hidden;
        max-width: 90%;
        align-self: center;
        .row {
            border-bottom: 1px solid #cecece;
        }
    }
    .questions {
        line-height: 1.6;
    }
    .remaining-time, .remaining-questions {
        text-align: right;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        letter-spacing: 2px;
        
        .title {
            font-weight: 300;
            font-size: 17px;
        }
        span {
            font-weight: 400;
            font-size: 24px;
            text-align: center;
        }
    }
    .remaining-questions {
        text-align: left;
        padding-left: 20px;
    }
}

.main-container {
    background: url("../images/home-banner.jpg") no-repeat right top;
    background-color: #eef1f6;

    .home-banner {
        height: 360px;
        border-bottom: 2px solid #383c3e;

        .slogan {
            margin-top: -50px;
        }

        .woman {
            margin-left: 60px;
        }
        @include smallDevice {
            .slogan {
                img {
                    width: 380px;
                }
            }
        }
        @include mediumDevice {
            height: 320px;

            .woman {
                display: none;
            }
        }
    }
    
    .home-content {
        padding-bottom: 40px;
        background-color: #f5f8fa;

        .home-card {
            margin-top: -40px;

            .home-card-body {
                background-color: $white;
                padding: 20px;
                min-height: 272px;
                @include border-radius-full();
            }
        }
    }

    .home-footer {
        background-color: #383c3e;
        color: #fff;

        .home-card {
            .home-card-body {
                border: 1px solid #f5f8fa;
                padding: 20px;
                min-height: 215px;
            }
        }
    }
    
}

.footer {
    display: block;
    width: 100%;
    margin: 0px;
    background: $footer-bg-color;
    padding: 15px 0px;
    color: #fff;
    font-size: 14px;

    @include mediumDevice {
        font-size: 13px;
    }
}
