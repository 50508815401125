@import "../scss/variables.scss";
@import "../scss/media.scss";

.productWrapper {
    width: 100%;
    display: block;
    margin: 20px 0px;

    .productArea {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;

        @include largeDevice {
            grid-template-columns: repeat(2, 1fr);
        }
        @include smallDevice {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.productDetailWrapper {
    display: block;
    margin: 20px 0px;
    width: 100%;

    .productLeftSideWrapper {
        background: #fff;
        width: 100%;
        border-radius: 10px;
        padding: 20px;

        .title {
            color: $font-color;
            font-weight: 800;
            font-size: 18px;
            display: block;
            text-align: left;
        }
        p {
            font-size: 14px;
            font-weight: 600;
            display: block;
            margin: 20px 0px;
            padding: 0px;
            color: $font-color;
        }
        .video {
            margin: 10px 0px;
            width: 100%;
            height: auto;
            border: none;
            border-radius: 4px;
        }
        .btnNext {
            background: $sub-info-bg-color;
            padding: 10px 15px;
            color: #fff;
            text-transform: uppercase;
            font-size: 15px;
            margin: 20px 0px;
            
            outline: none;
            border: none;
            border-radius: 10px;

            @include mediumDevice {
                margin: 10px 0px;
                font-size: 14px;
                padding: 10px;
            }
        }
    }
}

.subInfoWrapper {
    background: #0c6e46;
    height: 250px;
    display: flex;
    align-items: center;
    width: 100%;

    .title {
        font-size: 36px;
        color: #fff;
        display: block;
        width: 100%;
        font-weight: 700;
        line-height: 50px;
    }

    .time {
        display: flex;
        color: #fff;
        justify-content: flex-start;
        align-items: center;
    }

    .detail {
        margin: 10px 0px;
        display: block;
        font-size: 15px;
        color: #fff;
        line-height: 25px;
    }
}

.product {
    padding: 20px;
    background: #fff;
    border-radius: 4px;

    &.productDetailed {
        transform: translateY(-240px);

        @include mediumDevice {
            transform: translateY(0px);
        }
        ul {
            list-style: none;

            li {

                a {
                    color: #0c6e46;
                    font-weight: bold;
                }

                @include mediumDevice {
                    font-size: 13px;
                    margin-bottom: 5px;
                }
            }
        }
        .bottomArea {
            display: block;
            width: 100%;

            p {
                font-size: 14px;
                font-weight: 600;

                @include mediumDevice {
                    font-size: 13px;
                }
            }
        }
    }

    .imgWrapper {
        width: 100%;
        height: 230px;
        overflow: hidden;

        @include mediumDevice {
            height: auto;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .infoArea {
        font-size: 14px;

        .title {
            font-weight: 600;
            display: block;
            text-align: left;
            font-size: 18px;
            margin: 20px 0px;

            @include smallDevice {
                margin: 20px 0px;
            }
        }
        .subInfo {
            font-size: 15px;
            font-weight: 500;
            margin: 10px 0px;
        }
        .lessonArea {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .item {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-right: 20px;

                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                }
                span {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
    .bottomArea {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 20px;

        .btn {
            padding: 10px 20px;
            display: block;
            color: #fff;
            font-size: 14px;
            background-color: $font-color;
        }
    }
}
